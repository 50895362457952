import React from 'react';
import { MeSection } from './sections/MeSection';
import { ExperienceSection } from './sections/ExperienceSection';
import { CertificationsSection } from './sections/CertificationsSection';
import { EducationSection } from './sections/EducationSection';
import { Layout } from './components/Layout';
import { AboutSection } from './sections/AboutSection';
import { MouseWrapper } from './components/MouseWrapper';
import { Footer } from './components/Footer';
export var App = function () { return (React.createElement(MouseWrapper, null,
    React.createElement(Layout, null,
        React.createElement("div", { className: "flex flex-col lg:flex-row lg:gap-40" },
            React.createElement("div", { className: "lg:sticky lg:top-0 lg:h-screen" },
                React.createElement(MeSection, null)),
            React.createElement("div", { className: "flex flex-col" },
                React.createElement(AboutSection, null),
                React.createElement("hr", { className: "border-t border-gray-300 my-4" }),
                React.createElement(ExperienceSection, null),
                React.createElement("hr", { className: "border-t border-gray-300 my-4" }),
                React.createElement(EducationSection, null),
                React.createElement("hr", { className: "border-t border-gray-300 my-4" }),
                React.createElement(CertificationsSection, null),
                React.createElement(Footer, null)))))); };
