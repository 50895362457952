import React from 'react';
import { Summary } from '../components/Summary';
import { SectionNavigation } from '../components/SectionNavigation';
import { SocialMediaLinks } from '../components/SocialMediaLinks';
export var MeSection = function () { return (React.createElement("div", { className: "flex flex-col justify-between min-h-full pt-20 lg:pb-20" },
    React.createElement(Summary, null),
    React.createElement("div", { className: "hidden lg:block" },
        React.createElement(SectionNavigation, null)),
    React.createElement("div", { className: "flex-grow" }),
    React.createElement("div", { className: "mt-10 lg:mt-0" },
        React.createElement(SocialMediaLinks, null)))); };
