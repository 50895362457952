import React, { useEffect, useState } from 'react';
import { Events, scroller } from 'react-scroll';
import { SectionButton } from './SectionButton';
var sections = [
    { section: 'about', label: 'About' },
    { section: 'experience', label: 'Experience' },
    { section: 'education', label: 'Education' },
    { section: 'certifications', label: 'Certifications' },
];
export var SectionNavigation = function () {
    var _a = useState('about'), activeSection = _a[0], setActiveSection = _a[1];
    var handleScrollToSection = function (section) {
        scroller.scrollTo(section, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };
    var handleScroll = function () {
        var sections = [
            'experience',
            'education',
            'certifications',
            'about',
        ];
        var sectionOffsets = sections
            .map(function (section) {
            var element = document.getElementById(section);
            if (element) {
                var rect = element.getBoundingClientRect();
                return {
                    section: section,
                    rectTop: rect.top + window.scrollY,
                    rectBottom: rect.bottom + window.scrollY,
                };
            }
            return null;
        })
            .filter(function (sec) { return sec !== null; });
        var scrollPosition = window.scrollY + window.innerHeight / 2;
        var topThreshold = 700;
        var active = sectionOffsets.find(function (sec) {
            return sec.rectTop <= scrollPosition && sec.rectBottom >= scrollPosition;
        });
        if (scrollPosition < topThreshold) {
            setActiveSection('about');
        }
        else if (active) {
            setActiveSection(active.section);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        Events.scrollEvent.register('end', function (to) {
            setActiveSection(to);
        });
        return function () {
            window.removeEventListener('scroll', handleScroll);
            Events.scrollEvent.remove('end');
        };
    }, []);
    return (React.createElement("div", { className: "flex flex-col gap-4 items-start pt-10" }, sections.map(function (section) { return (React.createElement(SectionButton, { key: section.section, section: section.section, label: section.label, isSectionActive: activeSection === section.section, handleScrollToSection: function () { return handleScrollToSection(section.section); } })); })));
};
