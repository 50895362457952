import React from 'react';
export var SectionButton = function (_a) {
    var label = _a.label, isSectionActive = _a.isSectionActive, handleScrollToSection = _a.handleScrollToSection, section = _a.section;
    return (React.createElement("div", { className: "flex items-center gap-2 group ".concat(isSectionActive ? 'text-gray-100' : 'text-gray-400') },
        React.createElement("div", { className: "transition-all duration-300 ease-in-out h-0.25 ".concat(isSectionActive ||
                'group-hover:w-10 group-hover:bg-gray-100 group-hover:scale-110', " ").concat(isSectionActive
                ? 'w-10 bg-gray-100 scale-110'
                : 'w-5 bg-gray-400 scale-100') }),
        React.createElement("button", { className: "font-roboto ".concat(isSectionActive
                ? 'text-gray-100'
                : 'text-gray-400 group-hover:text-gray-100', " transition-colors duration-300 ease-in-out"), onClick: function () { return handleScrollToSection(section); } }, label)));
};
