import React, { useEffect, useState } from 'react';
export var MouseWrapper = function (_a) {
    var children = _a.children;
    var _b = useState({ x: 0, y: 0 }), mousePosition = _b[0], setMousePosition = _b[1];
    var handleMouseMove = function (event) {
        setMousePosition({
            x: event.clientX,
            y: event.clientY,
        });
    };
    useEffect(function () {
        window.addEventListener('mousemove', handleMouseMove);
        return function () {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: "pointer-events-none fixed inset-0 z-30 transition duration-300 lg:block hidden", style: {
                background: "radial-gradient(600px at ".concat(mousePosition.x, "px ").concat(mousePosition.y, "px, rgba(29, 78, 216, 0.15), transparent 80%)"),
            } }),
        children));
};
