import React from 'react';
import { Link } from './Link';
export var CertificationCard = function (_a) {
    var title = _a.title, image = _a.image, validFrom = _a.validFrom, verifyLink = _a.verifyLink;
    return (React.createElement("div", { className: "flex flex-row gap-5 items-center" },
        React.createElement("img", { src: image, alt: "cert", className: "w-[10rem] h-[10rem]" }),
        React.createElement("div", { className: "flex flex-col gap-3" },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: "font-bold text-gray-100" }, title),
                React.createElement("div", { className: "text-sm" }, validFrom)),
            React.createElement(Link, { label: "View certification", link: verifyLink }))));
};
