import React from 'react';
import { SkillChip } from './SkillChip';
import { Link } from './Link';
export var ExperienceCard = function (_a) {
    var duration = _a.duration, title = _a.title, description = _a.description, skills = _a.skills, addiotionalLink = _a.addiotionalLink;
    return (React.createElement("div", { className: "flex flex-row gap-1" },
        React.createElement("div", { className: "flex-shrink-0 w-1/3 text-sm" }, duration),
        React.createElement("div", { className: "flex flex-col gap-3" },
            React.createElement("div", { className: "font-bold text-gray-100" }, title),
            React.createElement("div", { className: "text-sm" }, description),
            React.createElement("div", { className: "flex flex-wrap gap-2" }, skills === null || skills === void 0 ? void 0 : skills.map(function (skill) { return React.createElement(SkillChip, { key: skill, label: skill }); })),
            addiotionalLink && (React.createElement(Link, { label: 'View project in GitHub', link: addiotionalLink })))));
};
