import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SocialMediaLink } from './SocialMediaLink';
var links = [
    {
        icon: FaGithub,
        url: 'https://github.com/juholehtimaki',
    },
    {
        icon: FaLinkedin,
        url: 'https://www.linkedin.com/in/juho-lehtim%C3%A4ki-32aa5819a/',
    },
];
export var SocialMediaLinks = function () { return (React.createElement("div", { className: "flex flex-row gap-5" }, links.map(function (link) { return (React.createElement(SocialMediaLink, { key: link.url, icon: link.icon, url: link.url })); }))); };
